<template>
  <v-card flat>
    <app-loader :value="$apollo.loading"/>
    <v-container>
      <nice-header>
        Order schedule
        <template v-slot:extra>
          <v-btn
              color="primary"
              @click="$refs.calendar.prev()"
              icon
              outlined
              class="mt-2"
          >
            <v-icon dark>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          {{ date | displayMonth }}
          <v-spacer></v-spacer>
          <v-btn
              icon
              outlined
              class="mt-2"
              color="primary"
              @click="$refs.calendar.next()"
          >
            <v-icon dark>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
      </nice-header>
      <v-row>
        <v-col cols="12">
          <v-sheet height="600">
            <v-calendar
                ref="calendar"
                v-model="date"
                :type="type"
                :start="start"
                :end="end"
                event-overlap-mode="column"
                :first-interval="firstDate"
                :interval-count="intervalCount"
                :events="events"
                @click:event="showEvent"
                :event-color="getEventColor"
                @change="getEvents"
            ></v-calendar>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>


<script>
import moment from 'moment'
import { accountData, calendar, stores } from '@/graphql'

export default {
  data: () => ({
    startMenu: false,
    date: moment().format('YYYY-MM-DD'),
    endMenu: false,
    events: [],
    type: 'month',
    types: ['month', 'day', 'week'],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),
  mounted() {
    this.$refs.calendar.checkChange()
  },
  computed: {
    store () {
      if (this.stores) {
        return this.stores.find((store) => this.$store.state.selectedStore === store.hashId)
      }
      return null
    },
    firstDate() {
      if (this.store) {
        return moment(this.store.openingHours[0].openingTime).format('h') - 1
      }
      return 8
    },
    intervalCount() {
      return 10
    },
    start() {
      return moment(this.date).startOf(this.type).format('YYYY-MM-DD')
    },
    end() {
      return moment(this.start).endOf(this.type).format('YYYY-MM-DD')
    }
  },
  apollo: {
    stores,
    accountData,
    calendar: {
      query: calendar,
      variables() {
        return {
          startDate: this.start,
          endDate: this.end
        }
      }
    }
  },
  watch: {
    start() {
      this.$apollo.queries.calendar.refetch()
    },
    end() {
      this.$apollo.queries.calendar.refetch()
    },
    calendar() {
      this.getEvents();
    }
  },
  filters: {
    displayMonth(value) {
      return moment(value).format('MMMM YYYY')
    }
  },
  methods: {
    getEventColor(event) {
      switch (event.status) {
        case 'COMPLETED':
          return 'success';
        case 'CANCELLED':
          return 'grey';
        default:
          return 'primary'
      }
    },
    showEvent(eve) {
      this.$router.push(`/orders/${eve.event.id}`)
    },
    getEvents() {
      const events = [];
      for (var i in this.calendar) {
        var event = this.calendar[i];
        var start = Math.max(moment(event.collectionDate), moment(this.start));
        var end = Math.min(moment(event.returnDate), moment(this.end));

        events.push({
          id: event.id,
          name: event.calendarDescription,
          start: moment(start).format('YYYY-MM-DD hh:mm'),
          end: moment(end).format('YYYY-MM-DD hh:mm'),
          status: event.status,
        })

      }
      this.events = events

    },
  },
}
</script>

